import isEmpty from 'is-empty';


export const validation = value => {
    let errors = {},
        emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if (isEmpty(value.email)) {
        errors.email = "Email is required"
    } else if (!(emailRegex.test(value.email))) {
        errors.email = "Invalid email"
    }
    if (isEmpty(value.password)) {
        errors.password = "Password is required"
    }
    if (value.showTwoFA==true && isEmpty(value.twoFACode)) {
        errors.twoFACode = "Two FA code is required"
        
    }
    return errors;
}

export const changePasswordvalidation = value => {
    let errors = {};
    // let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}$/g;
    const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{6,18}$/;
    if (isEmpty(value.oldPassword)) {
        errors.oldPassword = "Current password is required"
    }

    if (isEmpty(value.password)) {
        errors.password = "New password is required"
    } else if (!(passwordRegex.test(value.password))) {
        errors.password = "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18"
    }

    if (isEmpty(value.confirmPassword)) {
        errors.confirmPassword = "Confirm password is required"
    } else if (!(isEmpty(value.confirmPassword)) && value.password != value.confirmPassword) {
        errors.confirmPassword = "Passwords must match"
    }
    if ((value.oldPassword == value.password) && !(value.oldPassword == '' || value.password == '')) {
        errors.password = "Current password & new password are same, please set new password"
    }

    return errors;
}


export const forgetpassValid = value => {
    let errors = {}
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if (isEmpty(value.email)) {
        errors.email = "Email is required"
    } else if (!emailRegex.test(value.email)) {
        errors.email = "Invalid Email";
      }

    return errors;
}

export const ResetPasswordvalidation = value => {
    let errors = {};
    // let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}/g;
    const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{6,18}$/;

    if (isEmpty(value.password)) {
        errors.password = "New password is required"
    } else if (!(passwordRegex.test(value.password))) {
        errors.password = "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18"
    }

    if (isEmpty(value.confirmPassword)) {
        errors.confirmPassword = "Confirm password is required"
    } 
    else if (!(passwordRegex.test(value.confirmPassword))) {
        errors.confirmPassword = "ConfirmPassword should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18"
    }
    else if (!(isEmpty(value.confirmPassword)) && value.password != value.confirmPassword) {
        errors.confirmPassword = "Passwords must match"
    }

    return errors;
}



export const chatFileValidation = async (bannerlogo) => {
    console.log('detailsssssssssssssssssssntent:', bannerlogo);

    let imagename = bannerlogo
    console.log('imagenamwwwwwwwwwwe: ', imagename);
    const allowedTypes = ["image/jpeg","image/jpg", "image/png","image/webp", "video/mp4", "video/webm","application/pdf"];
    
    try {
        let errors = {}
        if (isEmpty(imagename)) {
            errors.image = "Image is required";
        }
        // else if(!allowedTypes.includes(imagename.type)){
        //     errors.image="Invalid format,Only allowed jpeg,png,webp,mp4,webm,pdf formats"
        // }
        if (!isEmpty(errors)) {
            return errors
        }
    }
    catch (err) {
        console.log('err: ', err);
    }
}

