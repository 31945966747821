import axios from "../config/axios";
import config from "../config/config";

const baseUrl = config.baseUrl;

export const homePageText = async (data) => {
  try {
    console.log("API: ", data);
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/homepage-text`,
      data: data,
    });
    return {
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (error) {
    return {
      errors: returnErr(error),
    };
  }
};

export const getHomePageText = async (data) => {
  try {
    // console.log("API: ", data)
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/getHomePage-text`,
      data: data,
    });
    return {
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (error) {
    return {
      errors: returnErr(error),
    };
  }
};
export const getcmsList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/cms-list?LanName=` +
        data.LanName +
        `&skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search +
        `&status=` +
        data.status +
        `&type=` +
        data.type,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const getcmsbyId = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/get-cms-id`,
      data: data,
    });
    return {
      result: respData.data.result,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const updateCms = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/update-cms`,
      data: data,
    });
    console.log(respData, "updateupdate");
    return {
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const addCms = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/add-cms`,
      data: data,
    });
    console.log(respData, "updateupdate");
    return {
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const getcategoryList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/cms-category-list?LanName=` +
        data.LanName +
        `&skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search +
        `&status=` +
        data.status,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const updateCategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/update-cms-category`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const fetchCategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/fetch-category`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      result: respData.data.result,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const addcmsSubcategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/add-cms-subcat`,
      data: data,
    });
    console.log(respData, "apirespdata");
    return {
      result: respData.data.result,
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const getcmssublist = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/cms-subcat?LanName=` +
        data.LanName +
        `&skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search +
        `&status=` +
        data.status,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const updatecmsSubcategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/update-cms-subcat`,
      data: data,
    });
    console.log(respData, "apirespdata");
    return {
      result: respData.data.result,
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const fetchsubCategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/fetch-subcategory`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      result: respData.data.result,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};


export const addsigninmessage = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/add-signin-message`,
      data: data,
    });
    console.log(respData, "apirespdata");
    return {
      result: respData.data.result,
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};

export const getsigninmessage = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/get-signin-message?skip=` +
      data.skip +
      `&limit=` +
      data.limit +
      `&status=` +
      data.status +
      `&LanName=` +
      data.LanName
      ,
      
    });
    console.log(respData, "apirespdata");
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
      message: respData.data.message,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const deletesigninmessage = async (data) => {
  try {
      
      let respData = await axios({
          method: 'post',
          url: `${baseUrl}/delete-signin-status`,
          data: data
      })
      console.log(respData,'respData')
      return {
          message: respData.data.message,
          success:respData.data.success
      }
  } catch (err) {
      return {
          errors: returnErr(err),
      }
  }
}
export const signinStatus = async (data) => {
  try {
      let respData = await axios({
          method: 'post',
          url: `${baseUrl}/update-signin-status`,
          data: { _id: data }
      })
      return {
          message: respData.data.message
      }
  } catch (error) {
      return {
          errors: returnErr(error)
      }
  }
}
export const updateSubcatstatus = async (data) => {
  try {
      
      let respData = await axios({
          method: 'post',
          url: `${baseUrl}/update-sub-category-status`,
          data:  data 
      })
      console.log(respData,'respData')
      return {
          message: respData.data.message,
          success:respData.data.success
      }
  } catch (err) {
      return {
          errors: returnErr(err),
      }
  }
}
function returnErr(err) {
  if (err.response && err.response.data && err.response.data.errors) {
    return err.response.data.errors;
  } else {
    return "";
  }
}