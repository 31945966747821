import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import DataTable, { createTheme } from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import { formatNumber } from "../helper/custommath.js";
import {
  InputLabel,
  FormControl,
  InputAdornment,
  OutlinedInput,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { getProfitLists, getTransactionList } from "../Action/ApiAction.js";
import moment from "moment";
import config from "../config/config.js"
import { getTxLink } from "../helper/networkdatas.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
// Recently Swap Trans Table
const columns = [
  {
    name: "Date",
    selector: (row) => row.date,
    width: "190px"
  },
  {
    name: "Address",
    selector: (row) => row.address,
    width: "230px"
  },
  {
    name: "Symbol",
    selector: (row) => row.symbol,
  },
  {
    name: "Pay",
    selector: (row) => row.srcamount,
  },
  {
    name: "Receive",
    selector: (row) => row.destamount,
  },
  {
    name: "Profit",
    selector: (row) => row.profit,
  },
  {
    name: "Contract Method",
    selector: (row) => row.contractMethod,
  },
  {
    name: "Profit USD($)",
    selector: (row) => {
      const profitPercentage = (row.profit / row.srcamount) * 100;
      return profitPercentage.toFixed(8)
    },
  },
  {
    name: "Hash",
    selector: (recentswaplist) => recentswaplist.txid,
    cell: (recentswaplist) =>
      <a href={getTxLink(config.chainId) + 'tx/' + recentswaplist.txid} target="_blank">
        <i className="fa-solid fa-arrow-up-long fa-rotate-45"></i>
      </a>
  }
];

export default function TransactionManagement(props) {
  // For Datatable Starts
  createTheme(
    "dark",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(0,0,0,.12)",
      },
      background: {
        default: "#0d0f36",
      },
      context: {
        background: "#0d0f36",
        text: "#DA1818",
      },
      divider: {
        default: "rgb(17 17 17)",
      },
      button: {
        default: "#FFFFFF",
        focus: "rgba(255, 255, 255, .54)",
        hover: "rgba(255, 255, 255, .12)",
        disabled: "rgba(255, 255, 255, .18)",
      },
      selected: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      highlightOnHover: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      striped: {
        default: "rgba(0, 0, 0, .87)",
        text: "#FFFFFF",
      },
    },
    "dark"
  );

  const [record, setRecord] = useState({
    'data': [],
    'count': 0
  })


  const [filter, setFilter] = useState({
    skip: 1,
    limit: 10,
    search: '',
    range: "all"
  })
  useEffect(() => {
    getSwapList(filter)
  }, [])

  async function getSwapList(reqQuery) {
    console.log('reqQuery: ', reqQuery);
    try {
      var response = await getTransactionList(reqQuery)
      let resultArr = []
      response && response.result && response.result.length > 0 && response.result.map((item) => {
        resultArr.push({
          'symbol': item.symbol,
          'address': item.address,
          'srcamount': item && item.srcamount ? formatNumber(item.srcamount, 8) : '0',
          'destamount': item && item.destamount ? formatNumber(item.destamount, 8) : '0',
          'profit': item.profit && item.profit ? formatNumber(item.profit, 8) : '0',
          "exchange": item.exchangeNames,
          'date': moment(item.createdAt).format('MMMM DD,YYYY hh:mm'),
          'profitUSD': item && item.profitUSD ? formatNumber(item.profitUSD, 8) : '0',
          'txid': item.txid,
          'contractMethod': item.contractMethod
        })
      })
      setRecord({
        'data': resultArr,
        count: response.totalRecords
      })
    } catch (err) {

    }
  }
  const handleChange = (event) => {
    let valuess = (event.target.value).toString()
    // setrange(valuess);
    let filterData = { ...filter, ...{ 'range': event.target.value } }
    setFilter(filterData)
    getSwapList(filterData)
  };
  const handlePageChange = page => {
    let filterData = { ...filter, ...{ 'page': page, 'skip': page } }
    setFilter(filterData)
    getSwapList(filterData)
  };

  const handleSearch = async (event) => {
    let filterData = { ...filter, ...{ 'search': event.target.value } }
    setFilter(filterData)
    getSwapList(filterData)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    let filterData = { ...filter, ...{ 'page': page, 'limit': newPerPage } }
    setFilter(filterData)
    getSwapList(filterData)
  };


  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <div className="container-fluid">
                <h2 className="page_title">Transaction History</h2>
                <div className="row py-3">
                  <div className="col-lg-12">
                    <div className="dashbox_inner">
                      <div className="top_filter_wrapper">
                        <FormControl
                          sx={{ width: "70ch" }}
                          variant="outlined"
                          size="small"
                          className="search_input"
                        >
                          <InputLabel htmlFor="outlined-adornment-search">
                            Search by Symbol,Address
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-search"
                            type="text"
                            endAdornment={
                              <InputAdornment position="start">
                                <IconButton aria-label="Search Icon" edge="end">
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Search by Symbol"
                            onChange={handleSearch}
                          />
                        </FormControl>
                      </div>
                      <DataTable
                        columns={columns}
                        data={record.data}
                        paginationTotalRows={record.count}
                        responsive
                        theme="dark"
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        paginationServer
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}