import axios from '../config/axios'
import config from '../config/config'


const baseUrl = config.baseUrl
export const getwalletlist = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url:
                `${baseUrl}/admin-wallet-list?skip=` +
                data.skip +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search
        
        })
        return {
            result: respData.data.result,
            totalrecords: respData.data.totalrecords,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}
export const addAdmin = async (param) =>{
    try {
        console.log(param,'paramparamparam')
        
        let newname = `${param.name.toLowerCase()}.png`
        var formData = new FormData();
        formData.append('name', param.name);
        formData.append('email', param.email);
        formData.append('password', param.password);
        formData.append('Phonenumber', param.Phonenumber);
        formData.append('department', param.department);
        formData.append('position', param.position);
        formData.append('file', param.lplogo,newname);
        formData.append('status', param.status);
        let respData = await axios({
            method: 'post',
            url:`${baseUrl}/add-admin`,
            data: formData, 
        })
        return {
            success: respData.data.success,
            message: respData.data.message,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
    
};
export const UpdateAdmin = async (param) =>{
    try {
        let newname = `${param.name.toLowerCase()}.png`
        var formData = new FormData();
        formData.append('_id', param._id);
        formData.append('name', param.name);
        formData.append('email', param.email);
        formData.append('password', param.password);
        formData.append('Phonenumber', param.Phonenumber);
        formData.append('department', param.department);
        formData.append('position', param.position);
        if(param.logoURI!=''){
            formData.append('file', param.logoURI, newname)
        }
        formData.append('status', param.status);
        let respData = await axios({
            method: 'post',
            url:`${baseUrl}/update-admin`,
            data: formData, 
        })
        return {
            success: respData.data.success,
            message: respData.data.message,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
};
export const getadminList = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url:
                `${baseUrl}/admin-list?skip=` +
                data.skip +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search+
                `&status=` +
                data.status+
                `&role=`+
                data.role+
                `&startdate=`+
                data.startdate
        })
        return {
            result: respData.data.result,
            totalrecords: respData.data.totalrecords,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}
export const deleteAdmin = async (data) =>{
    try {
        let respData = await axios({
            method: 'post',
            url:`${baseUrl}/delete-admin`,
            data: data, 
        })
        return {
            result: respData.data.result,
            totalrecords: respData.data.totalrecords,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
};

export const addAdmincategory = async (data) =>{
    try {
        let respData = await axios({
            method: 'post',
            url:`${baseUrl}/add-admin-category`,
            data: data, 
        })
        return {
            result: respData.data.result,
            message: respData.data.message,
            success:respData.data.success
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
};
export const getadmincategoryList = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url:
                `${baseUrl}/admin-category-list?skip=` +
                data.skip +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search+
                `&status=` +
                data.status+
                `&role=`+
                data.role+
                `&startdate=`+
                data.startdate
        })
        return {
            result: respData.data.result,
            totalrecords: respData.data.totalrecords,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}
export const categoryStatus = async (data) => {
    try {
        let respData = await axios({
            method: 'post',
            url: `${baseUrl}/admin-category-status`,
            data: { _id: data }
        })
        return {
            message: respData.data.message
        }
    } catch (error) {
        return {
            errors: returnErr(error)
        }
    }
}
export const categorydelete = async (data) => {
    try {
        let respData = await axios({
            method: 'post',
            url: `${baseUrl}/admin-category-delete`,
            data: { _id: data }
        })
        return {
            message: respData.data.message,
            success: respData.data.success
        }
    } catch (error) {
        return {
            errors: returnErr(error)
        }
    }
}
export const updateAdmincategory = async (data) =>{

    try {
        
        let respData = await axios({
            method: 'post',
            url:`${baseUrl}/update-admin-category`,
            data: data, 
        })
        
        return {
            result: respData.data.result,
            message: respData.data.message,
            success:respData.data.success
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
    
};
export const getUrlList = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url: `${baseUrl}/get-social-url`,
        })
        return {
            result: respData.data.data,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}
export const getAdmincategory = async () => {
    try {
        let respData = await axios({
            method: 'get',
            url: `${baseUrl}/get-admin-category`,
        })
        return {
            result: respData.data.result,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}

export const siteurl = async (data) => {
    try {
        let respData = await axios({
            method: 'post',
            url: `${baseUrl}/site-social-url`,
            data: data,
        })
        return {
            status:respData.data.status,
            message: respData.data.message,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}
export const metaDetails = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url: `${baseUrl}/meta-details`,
            data: data,
        })
        return {
            result: respData.data.data,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}
export const updatemetadetails = async (data) => {
    try {
        let respData = await axios({
            method: 'post',
            url: `${baseUrl}/meta-details`,
            data: data,
        })
        return {
            status:respData.data.status,
            message: respData.data.message,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}
export const getAdmin = async (data) =>{
    try {
        let respData = await axios({
            method: 'post',
            url:`${baseUrl}/get-admin`,
            data: data, 
        })
        return {
            result: respData.data.data,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
    
};
function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors;
    }
    else {
        return '';
    }
}
