
import { convert } from './convert';


export function formatNumber(num, defaultFixed) {

    try {
        if (defaultFixed && parseInt(defaultFixed) > 0) {
            defaultFixed = parseInt(defaultFixed);
        } else {
            defaultFixed = 5;
        }
        var numval = num.toString();
        numval = convert(numval);

        var chkDeci = numval.split(".");
        var returnNum = num;
        if (chkDeci.length == 2) {
            if (defaultFixed < chkDeci[1].length) {
                returnNum = toFixedWithoutRound(numval, defaultFixed);
            } else {
                var fix = chkDeci[1].length;
                returnNum = toFixedWithoutRound(numval, fix);
            }
        }

        return returnNum;
    } catch (err) {
        console.log(err, 'errerrerrerr')
        return 0;
    }

}

export function toFixedWithoutRound(number, decimalPlaces = 2) {
    try {
        let numberString = number.toString();
        let decimalIndex = numberString.indexOf('.');
        if (decimalIndex === -1) {
            return number;
        }
        numberString = numberString.slice(0, decimalIndex + decimalPlaces + 1);
        let truncatedNumber = parseFloat(numberString);
        return truncatedNumber;
    } catch (err) {
        return 0;
    }

}