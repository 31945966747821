import React, { useState, useEffect } from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import Dashboard from "./pages/dashboard.js";
// import Dashboard from "./pages/dashboardNew.js";

import ChangePassword from "./pages/change-password.js";
import Login from "./pages/login.js";
import ForgotPassword from "./pages/forgot-password.js";
import { SnackbarProvider } from "notistack";
import ConditionRoute from "./routes/ConditionRoute.js";
import ResetPassword from "./pages/reset-password.js";
import Profile from "./pages/profile";
import NotFound from "./pages/not-found.js";
import { tokencheck } from "../src/helper/jwttoken";
import ProfitManagement from "./pages/profit-management.js";
import PairManagement from "./pages/pair-management.js";
import TokenManagement from "./pages/token-management.js";
import TransactionManagement from "./pages/Transaction-history.js";
const AppWrapper = () => {
  const [data, setData] = useState([]);
  const [role, setRole] = useState("");
  async function getrestriction() {
    try {
      var admintoken = localStorage.getItem("admin_token");
      var decoded = await tokencheck(admintoken);
      console.log(decoded, "decoded");
      setData(decoded.restriction);
      setRole(decoded.role);
    } catch (err) {
      console.log(err, "eeeeeeeeeeee");
    }
  }

  useEffect(() => {
    getrestriction();
  }, []);

  return (
    <BrowserRouter basename="/">
      <SnackbarProvider />
      <Switch>
        <ConditionRoute exact path="/login" component={Login} type={"auth"} />
        <ConditionRoute
          exact
          path="/change-password"
          component={ChangePassword}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
          type={"auth"}
        />
        <ConditionRoute
          exact
          path="/reset-password/:userId"
          component={ResetPassword}
          type={"auth"}
        />
        <ConditionRoute
          exact
          path="/profit-list"
          component={ProfitManagement}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/pair-list"
          component={PairManagement}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/token-management"
          component={TokenManagement}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/transaction-history"
          component={TransactionManagement}
          type={"private"}
        />
        {/* <ConditionRoute
          exact
          path="/profile"
          component={Profile}
          type={"private"}
        /> */}
        <ConditionRoute
          exact
          path="/"
          component={Dashboard}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/dashboard"
          component={Dashboard}
          type={"private"}
        />
        <ConditionRoute exact path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
export default AppWrapper;
