import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import DataTable, { createTheme } from "react-data-table-component";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { CircularProgressBar } from "@tomickigrzegorz/react-circular-progress-bar";
import Footer from "../components/Footer.js";
import {
  getTotalcountsAction,
} from "../Action/ApiAction.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


var initialValue = {
  countprofits: 0,
  countpairs: 0,
  counttokens: 0,
  todayprofits: 0,
  todayProfitUsd: 0

};


export default function Dashboard() {
  const [formvalue, setformvalue] = useState(initialValue);
  const {
    countprofits,
    countpairs,
    counttokens,
    todayprofits,
    todayProfitUsd,
  } = formvalue;

  useEffect(() => {
    getTotalcountss()
  }, []);

  const getTotalcountss = async () => {
    const { result } = await getTotalcountsAction();
    console.log('resusslt: ', result);
    setformvalue({ ...formvalue, ...result })
  }


  // For Datatable Starts
  createTheme(
    "dark",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(0,0,0,.12)",
      },
      background: {
        default: "#201f21",
      },
      context: {
        background: "#201f21",
        text: "#DA1818",
      },
      divider: {
        default: "rgb(32 31 33)",
      },
      button: {
        default: "#FFFFFF",
        focus: "rgba(255, 255, 255, .54)",
        hover: "rgba(255, 255, 255, .12)",
        disabled: "rgba(255, 255, 255, .18)",
      },
      selected: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      highlightOnHover: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      striped: {
        default: "rgba(0, 0, 0, .87)",
        text: "#FFFFFF",
      },
    },
    "dark"
  );
  // For Datatable Ends
console.log(formvalue,'formvalueformvalue')
  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
              <div className="page_content dashboard_page">
                <div className="container-fluid">
                  <h2 className="page_title">Dashboard</h2>
                  <div className="row py-2">
                    <div className="col-lg-12">
                      <div className="widget_panel">
                        <div className="widget_panel_single">
                          <div className="widget_panel_single_inner">
                            <CircularProgressBar
                              colorCircle="#8A92A6"
                              colorSlice="#D3D3D3"
                              number={false}
                              percent={56}
                              strokeBottom={2}
                              stroke={5}
                              size={68}
                              animationSmooth="1s ease-out"
                              round
                            >
                              <img
                                src={require("../assets/images/cross_arrow_icon.png")}
                                className="img-fluid"
                              />
                            </CircularProgressBar>
                          </div>
                          <div>
                            <h6 className="ylw_txt">Total Profit Count</h6>
                            <h5>

                              {Number.isInteger(countprofits)
                                ? parseInt(countprofits)
                                : parseFloat(countprofits).toFixed(6)}
                            </h5>
                          </div>
                        </div>
                        <div className="widget_panel_single">
                          <div className="widget_panel_single_inner">
                            <CircularProgressBar
                              colorCircle="#8A92A6"
                              colorSlice="#DA1818"
                              number={false}
                              percent={68}
                              strokeBottom={2}
                              stroke={5}
                              size={68}
                              animationSmooth="1s ease-out"
                              round
                            >
                              <img
                                src={require("../assets/images/cross_arrow_icon.png")}
                                className="img-fluid"
                              />
                            </CircularProgressBar>
                          </div>
                          <div>
                            <h6 className="ylw_txt">Total Pair Count</h6>
                            <h5>

                              {Number.isInteger(countpairs)
                                ? parseInt(countpairs)
                                : parseFloat(countpairs).toFixed(6)}
                            </h5>
                          </div>
                        </div>
                        <div className="widget_panel_single">
                          <div className="widget_panel_single_inner">
                            <CircularProgressBar
                              colorCircle="#8A92A6"
                              colorSlice="#08B1BA"
                              number={false}
                              percent={62}
                              strokeBottom={2}
                              stroke={5}
                              size={68}
                              animationSmooth="1s ease-out"
                              round
                            >
                              <img
                                src={require("../assets/images/cross_arrow_icon.png")}
                                className="img-fluid"
                              />
                            </CircularProgressBar>
                          </div>
                          <div>
                            <h6 className="ylw_txt">Total Tokens Count</h6>
                            <h5>

                              {Number.isInteger(counttokens)
                                ? parseInt(counttokens)
                                : parseFloat(counttokens).toFixed(6)}
                            </h5>
                          </div>
                        </div>
                        <div className="widget_panel_single">
                          <div className="widget_panel_single_inner">
                            <CircularProgressBar
                              colorCircle="#8A92A6"
                              colorSlice="#1aa053"
                              number={false}
                              percent={71}
                              strokeBottom={2}
                              stroke={5}
                              size={68}
                              animationSmooth="1s ease-out"
                              round
                            >
                              <img
                                src={require("../assets/images/cross_arrow_icon.png")}
                                className="img-fluid"
                              />
                            </CircularProgressBar>
                          </div>
                          <div>
                            <h6 className="ylw_txt">Today Profit Count</h6>
                            <h5>
                              {Number.isInteger(todayprofits)
                                ? parseInt(todayprofits)
                                : parseFloat(todayprofits).toFixed(6)}
                            </h5>
                          </div>
                        </div>
                        <div className="widget_panel_single">
                          <div className="widget_panel_single_inner">
                            <CircularProgressBar
                              colorCircle="#8A92A6"
                              colorSlice="#FFD329"
                              number={false}
                              percent={71}
                              strokeBottom={2}
                              stroke={5}
                              size={68}
                              animationSmooth="1s ease-out"
                              round
                            >
                              <img
                                src={require("../assets/images/cross_arrow_icon.png")}
                                className="img-fluid"
                              />
                            </CircularProgressBar>
                          </div>
                          <div>
                            <h6 className="ylw_txt">Today Profit</h6>
                            <h5>$
                              {Number.isInteger(todayProfitUsd)
                                ? parseInt(todayProfitUsd)
                                : parseFloat(todayProfitUsd).toFixed(6)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}