import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import {
  Button
} from "@mui/material";
import { toastAlert } from "../helper/toastAlert";
import { forgotPassword } from "../Action/ApiAction";
import isEmpty from "is-empty";
import { forgetpassValid } from "./validation";
import {getUrlList} from "../Action/UserAction"
import config from "../config/config"
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  'email': '',
}
export default function ForgotPassword() {
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [logodata, setLogodata] = useState();
  const { email } = formValue;
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } }
    setFormValue(formData)
    setValidateError({})
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let reqData = {
      email: email,
      identifier: "User_forgot"
    }
    let validationError = forgetpassValid(reqData)
    if (!isEmpty(validationError)) {
      setValidateError(validationError)
      return
    }
    let { status, errors, message } = await forgotPassword(reqData);
    if (status == true) {
      setFormValue(initialFormValue)
      toastAlert('success', 'Kindly check your email');
    } else {
      if (errors) {
        setValidateError(errors);
      }
    }
  }
  async function getLogo() {
   
    var response = await getUrlList()
    var list1 = response && response.result ? response.result : {}
    setLogodata(list1)
    console.log(list1,'logodata')
  }
  useEffect(() => {
    getLogo()
    
  }, [])
  return (
    <>
      <ScrollToTopOnMount />
      <div className="auth_wrapper">
        <div className="container">
          <div className="auth_main auth_main_forgot">
            <div className="auth_left">
              <form className="auth_form">
              {logodata && logodata.logoURI && <img src={`${
                      config.imageUrl+'/logo/'+logodata.logoURI
                      }`} alt="Logo" className="img-fluid" />}
                <h2>Password Help </h2>
                <p>Enter your email address. you will receive an email with a link to reset your password</p>
                <div className="form-group">
                  <label>Email Address</label>
                  <input className="form-control" type="email" name="email" value={email}
                    onChange={handleChange} />
                  {
                    validateError.email && <p className="error-message" style={{ color: "red" }}>{validateError.email}</p>
                  }
                </div>
                <Link to="/login">Back to Login</Link>
                <div className="text-center mt-3">
                  <Button variant="contained" onClick={handleFormSubmit}>Retrieve </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}