import axios from '../config/axios'
import config from "../config/config";
import { setAuthToken } from '../helper/localstorage';
const baseUrl = config.baseUrl;

//adminLogin
export const login = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/loginadmin`,
            data: data
        });
        //  setAuthToken(respData.data.token)
        return {
            success: respData.data.success,
            errors: respData.data.errors,
            token: respData.data.token,
            message: respData.data.message,
            status: respData && respData.data && respData.data.status ? respData.data.status : false,
        }
    }
    catch (err) {
        return {
            loading: true,
            errors: returnErr(err),
            result: null
        }
    }
}


export const getProfitLists = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `${baseUrl}/profit-history?skip=` +
                 data.skip +
                `&limit=` + data.limit +
                `&search=` + data.search +
                `&range=` + data.range 
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.data,
            totalRecords:respData.data.totalRecords
        };
    } catch (err) {
        console.log('err: ', err);

        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        };
    }
};


export const getTransactionList = async (data) => {
    console.log('dataaaaaaaaaaaaaaa: ', data);
    try {
        let respData = await axios({
            method: "get",
            url: `${baseUrl}/transaction-lists?skip=` +
                 data.skip +
                `&limit=` + data.limit +
                `&search=` + data.search +
                `&range=` + data.range 
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.data,
            totalRecords:respData.data.totalRecords
        };
    } catch (err) {
        console.log('err: ', err);

        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        };
    }
};

export const getPairLists = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `${baseUrl}/pair-history?skip=` +
                 data.skip +
                `&limit=` + data.limit +
                `&search=` + data.search
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.data,
            totalRecords:respData.data.totalRecords
        };
    } catch (err) {

        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        };
    }
};




export const get2faCode = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `${baseUrl}/security/2fa`,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {

        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        };
    }
};

export const update2faCode = async (data) => {
    try {
        let respData = await axios({
            method: "put",
            url: `${baseUrl}/security/2fa`,
            data,
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {

        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        };
    }
};
export const disabled2faCode = async (data) => {
    try {
        let respData = await axios({
            method: "patch",
            url: `${baseUrl}/security/2fa`,
            data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {

        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};
export const getAdminDetailsAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/getAdminDetails`,
            data
        });
        return {
            status: 'true',
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const changePassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/change-password`,
            data: data
        });
        return {
            success: respData.data.success,
            message: respData.data.message,
            errors: respData.data.errors,
            token: respData.data.token,
        }
    }
    catch (err) {
        return {
            loading: true,
            errors: returnErr(err),
            result: null
        }
    }
}
export const ResetPasswordAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/reset-passwordd`,
            data: data
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            errors: respData.data.errors,
            token: respData.data.token,
        }
    }
    catch (err) {
        return {
            loading: true,
            errors: returnErr(err),
            result: null
        }
    }
}
export const forgotPassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/forgott-password`,
            data: data
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            errors: respData.data.errors,
            token: respData.data.token,
        }
    }
    catch (err) {
        return {
            loading: true,
            errors: returnErr(err),
            result: null
        }
    }
}

export const getAllAdminAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-adminInfo`,
            data: data
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result && respData.data.result.adminData) ? respData.data.result.adminData : [],
            unchatresult: (respData && respData.data && respData.data.result && respData.data.result.unchatadmins) ? respData.data.result.unchatadmins : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const customerListAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/customerCount`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getuserListAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/get-userlist`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getToppairTradingAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/getpair-tradinglist`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const saveMessages = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-chat`,
            data: data
        });
        return {
            loading: true,
            results: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getallchatAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/getall-chat`,
            data: data
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getLastMsgAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/getlast-Message`,
            data: data
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const recentSwapTransactionAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/getswap-list`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const recentBridgeTransactionAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/getbridge-list`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const AdminFeeChartAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/adminfee-chart`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getTotalcountsAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/totalCalculations`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.data) ? respData.data.data : [],

        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const ActvityOverviewAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/activity_overview`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const totalvisitsChartAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/total_visitsChart`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const trafficChartAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/traffic-Chart`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getsalesdataAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/sales-Chart`,
            data: data
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const SaveBridgeFee = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-bridgefee`,
            data: data
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            message: (respData && respData.data && respData.data.message) ? respData.data.message : 'Failed'
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const SaveSwapFee = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-swapfee`,
            data: data,
            headers: {
                Secretkey: config.secretKey
            }
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            message: (respData && respData.data && respData.data.message) ? respData.data.message : 'Failed'
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const getswapfees = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-swapfee`,
            data: data
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            message: (respData && respData.data && respData.data.message) ? respData.data.message : 'Failed',
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {}
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getFeeCommissionAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-feecommission`,
            data: data
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            message: (respData && respData.data && respData.data.message) ? respData.data.message : 'Failed',
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const updateFeeCommission = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/update-feecommission`,
            data: data
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            message: (respData && respData.data && respData.data.message) ? respData.data.message : 'Failed',
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const WithdrawListAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            url:
                `${baseUrl}/withdraw-list?skip=` +
                data.skip +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : [],
            totalrecords: respData.data.totalCount,

        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const CommissionHistyAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            url:
                `${baseUrl}/commission-histy?skip=` +
                data.skip +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search +
                `&chainId=` +
                data.network +
                `&startdate=` +
                data.startdate
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : [],
            totalrecords: respData.data.totalCount,
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const WithdrawHistoryAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            url:
                `${baseUrl}/get-withdrawhisty?skip=` +
                data.skip +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : [],
            totalrecords: respData.data.totalCount,

        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors;
    }
    else {
        return '';
    }
}