import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
} from "@mui/material";
import { red } from '@mui/material/colors'
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import config from '../../config/config'
import Web3 from 'web3'
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from 'ethereum-multicall'
import { getFormatMulticall } from '../../helper/multicallreturn'
import isEmpty from '../../helper/isEmpty'
import { toastAlert } from '../../helper/toastAlert'
import BEP20ABI from '../../ABI/BEP20.json'
import { addToken, updateToken } from '../../Action/Swapping'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const Error = styled('span')(({ theme }) => ({
  color: red,
}))
var initialvalue = {
  _id: '',
  name: '',
  symbol: '',
  decimals: '',
  totalSupply: '',
  address: '',
  lplogo: '',
  logoURI: '',
  chainId: 1,
  status: true,
  tokendate: new Date()
}
// eslint-disable-next-line react/prop-types
export default function AddToken(props) {
  var {  Edit, Editdata } = props
  const [startDate, setStartDate] = React.useState(new Date())
  const [farmData, setfarmData] = React.useState(initialvalue)
  const [tokenimage, settokenimage] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [validatation, setvalidatation] = React.useState({})
  const onChange = (event) => {
    var newData = {
      [event.target.name]: event.target.value,
    }
    setfarmData({ ...farmData, ...newData })
    setvalidatation({})
  }
  const onstatusChange = (event) => {
    var newData = {
      [event.target.name]: event.target.checked,
    }
    setfarmData({ ...farmData, ...newData })
  }
  const handleChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      settokenimage(true)
      var file = event.target.files[0]
      var url = URL.createObjectURL(file)
      var newData = {
        lplogo: file,
        logoURI: url,
      }
      setfarmData({ ...farmData, ...newData })
      setvalidatation({})
    }
  }
  const handleDateChange = (date) => {
    setStartDate(date)
    var newData = {
      tokendate: date
    }
    setfarmData({ ...farmData, ...newData })
  }
  async function SubmitFarm() {
    setLoading(true)
    try {
      let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/
      var errors = {}
      if (farmData.logoURI == '') {
        if (farmData.lplogo && farmData.lplogo.size) {
          if (farmData.lplogo.size > 2048000) {
            errors.logoURI = 'Image size must be less than 2mb'
            setvalidatation(errors)
            return
          } else if (!imageFormat.test(farmData.lplogo.name)) {
            errors.logoURI =
              'Invalid Image Format.only jpeg,jpg,png accepted'
            setvalidatation(errors)
            return
          }
        } else {
          errors.logoURI = 'Please Select Logo'
          setvalidatation(errors)
          setLoading(false)
          return
        }
      }
      if (farmData.address == '') {
        errors.address = 'Please Enter Contract Address'
        setvalidatation(errors)
        setLoading(false)
        return
      }
      let chainId = parseInt(farmData.chainId)
      var rpc = config.rpc[chainId]
      var web3 = new Web3(rpc)
      var fromtokens = web3.utils.isAddress(farmData.address)
      setLoading(true)
      setvalidatation({})
      if (!fromtokens) {
        errors.address = 'Please Enter Valid Contract Address'
        setvalidatation(errors)
        setLoading(false)
        return
      }
      try {
        var web3 = new Web3(rpc)
        const multicall = new Multicall({ web3Instance: web3 })
        console.log(farmData.address,'farmData.addressfarmData.address')
        const contractCallContext: ContractCallContext[] = {
          reference: 'Eth20contract',
          contractAddress: farmData.address,
          abi: BEP20ABI,
          calls: [
            {
              reference: 'name',
              methodName: 'name',
            },
            {
              reference: 'symbol',
              methodName: 'symbol',
            },
            {
              reference: 'decimals',
              methodName: 'decimals',
            },
            {
              reference: 'totalSupply',
              methodName: 'totalSupply',
            },
          ],
        }
        const results: ContractCallResults = await multicall.call(
          contractCallContext
        )
        console.log(results,'results176')
        var getname = await getFormatMulticall(
          results,
          'Eth20contract',
          0
        )
        console.log(getname,'getname')
        var getsymbol = await getFormatMulticall(
          results,
          'Eth20contract',
          1
        )
        var getdecimals = await getFormatMulticall(
          results,
          'Eth20contract',
          2
        )
        var gettotalSupply = await getFormatMulticall(
          results,
          'Eth20contract',
          3
        )
        if (isEmpty(getname)) {
        
          errors.address = 'Please enter valid contract address'
          toastAlert('warning', 'Name not found this contract')
          setvalidatation(errors)
          setLoading(false)
          return
        } else if (isEmpty(getsymbol)) {
          errors.address = 'Please enter valid contract address'
          toastAlert('warning', 'symbol not found this contract')
          setvalidatation(errors)
          setLoading(false)
          return
        } else if (isEmpty(getdecimals)) {
          errors.address = 'Please enter valid contract address'
          toastAlert('warning', 'decimals not found this contract')
          setLoading(false)
          setvalidatation(errors)
          return
        } else if (isEmpty(gettotalSupply)) {
          errors.address = 'Please enter valid contract address'
          toastAlert('warning', 'totalsupply not found this contract')
          setLoading(false)
          setvalidatation(errors)
          return
        }
      } catch (err) {
        errors.address = 'Please enter valid contract address'
        setvalidatation(errors)
        setLoading(false)
        return
      }
      if (Edit) {
        try {
          const updateFormdata = {
            _id: farmData._id,
            name: getname,
            address: farmData.address,
            symbol: getsymbol,
            decimals: getdecimals,
            totalSupply: gettotalSupply,
            logoURI: farmData && farmData.lplogo ? farmData.lplogo : '',
            file: tokenimage,
            chainId: farmData.chainId,
            status: farmData.status == true ? 'active' : 'inactive',
            tokendate: farmData && farmData.tokendate?farmData.tokendate:new Date()
          }
          let { success, message } = await updateToken(updateFormdata)
          if (success == false) {
            window.$("#add_token").modal("hide")
            toastAlert('error', message)
            setLoading(false)
          }
          else {
            setLoading(false)
            toastAlert('success', message)
            window.$("#add_token").modal("hide")
            setTimeout(() => {
              window.location.reload()
            }, '3000')
          }
        } catch (err) {
          setLoading(false)
        }
      } else {
        try {
          const newForm = {
            name: getname,
            symbol: getsymbol,
            decimals: getdecimals,
            address: farmData.address,
            totalSupply: gettotalSupply,
            logoURI: farmData.lplogo,
            chainId: farmData.chainId,
            status: farmData.status == true ? 'active' : 'inactive',
            tokendate: farmData.tokendate
          }
          let { success, message } = await addToken(newForm)
          if (success == false) {
            window.$("#add_token").modal("hide")
            toastAlert('error', message)
            setLoading(false)
          }
          else {
            toastAlert('success', message)
            window.$("#add_token").modal("hide")
            setTimeout(() => {
              window.location.reload()
            }, '3000')
            setLoading(false)
          }
        } catch (err) {
          setLoading(false)
          return
        }
      }
    }
    catch (err) {
    }
  }
  useEffect(() => {
    if (Editdata) {
      setfarmData(Editdata)
    }
  }, [Editdata])
  function handleClose() {
    setfarmData(initialvalue)
    setvalidatation({})
    window.$("#add_token").modal("hide")
  }
  return (
    <div
      className="modal fade primary_modal"
      id="add_token"
      tabindex="-1"
      aria-labelledby="add_token_modal"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="add_tokenLabel">
              {Edit == true ? 'Update Token' : 'Add token'}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
            <form className="primary_form">
              <div className="user_create_switch">
                <input className="input-switch" type="checkbox" id="status" name="status" checked={farmData.status} onChange={onstatusChange} />
                <label className="label-switch" for="status"></label>
                <span className="info-text"></span>
              </div>

              <div className="form-group image_upload">
                <Button component="label" variant="contained">
                  Upload
                  <VisuallyHiddenInput type="file" id="lplogo" onChange={handleChange} accept="image/png, image/jpeg, image/svg+xml" />
                </Button>
                {farmData &&
                  farmData.logoURI &&
                  farmData.logoURI != '' &&
                  (tokenimage ? (
                    <img src={farmData.logoURI} alt="Icon" className="img-fluid" />
                  ) : (
                    <img src={`${
                      farmData.logoURI
                      }`} alt="Icon" className="img-fluid" />

                  ))}

                {validatation.logoURI && validatation.logoURI != '' && (
                  <Error style={{ color: 'red' }}>
                    {validatation.logoURI}
                  </Error>
                )}
              </div>
              <div className="form-group">
                <label>Network type</label>
                <FormControl size="small" fullWidth>
                  <Select
                    displayEmpty
                    value={farmData.chainId}
                    onChange={onChange}
                    labeltext="chainId"
                    inputProps={{
                      name: 'chainId',
                      id: 'chainId',
                    }}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={1}>Ethereum</MenuItem>
                    <MenuItem value={56}>BNB Chain</MenuItem>
                    <MenuItem value={137}>Polygon</MenuItem>
                    <MenuItem value={43114}>Avalanche</MenuItem>
                    <MenuItem value={250}>Fantom</MenuItem>
                    <MenuItem value={42161}>Arbitrum</MenuItem>
                    <MenuItem value={10}>Optimism</MenuItem>
                    <MenuItem value={8453}>Base</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="form-group">
                <label>Token Address</label>
                <FormControl variant="outlined" size="small" fullWidth>
                  <OutlinedInput
                    name="address"
                    placeholder="Enter Token Address"
                    aria-describedby="Username"
                    inputProps={{
                      "aria-label": "Username",
                    }}
                    value={farmData.address}
                    validators={['required']}
                    errorMessages={['This field is required']}
                    onChange={onChange}
                  />
                </FormControl>
                {validatation.address && validatation.address != '' && (
                  <Error style={{ color: 'red' }}>
                    {validatation.address}
                  </Error>
                )}
              </div>
              <div className="form-group">
                <label>Token list time</label>
                <div className="custom_datepicker">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => handleDateChange(date)}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
              </div>
              <div className="text-center">
                <Button variant="contained" onClick={() => SubmitFarm()}>  {loading && <i class="fas fa-spinner fa-spin"></i>} {Edit == true ? 'Update' : 'Add'}  </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};