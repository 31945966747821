import React, { useEffect, useState } from "react";
import { getHomePageText } from "../Action/homePage";
export default function Footer(props) {
  const [textData, setTextData] = useState({});
  useEffect(() => {
    getList();
  }, []);

  async function getList() {
  
    const result = await getHomePageText({ LanName: "en" });
    console.log("INADMIN: ", result);
    console.log("INADMIN: ", result.result);
    if (result.result) {
      setTextData(result.result);
    }
  }
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_right">
            {/* <ul className="footer_menu_links">              
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Terms of Use</a>
              </li>
            </ul> */}
          </div>
          <div className="footer_panel_left">
            {/* <p>© {new Date().getFullYear()} <a href="https://www.hodl.international/" target="_blank">BuddyEx.</a></p> */}
            {textData && textData.copyrighttext ? <p>
              <a href="#" target="_blank">{textData.copyrighttext}</a>
            </p>:''}
          </div>
        </div>
      </div>
    </footer>
  );
}
