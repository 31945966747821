import mainetTxLink from "./mainnet/txLink";

var txLink = mainetTxLink;

var key = {};

if (process.env.NODE_ENV === "production") {
  key = {
    baseUrl: "https://buddyexapi.wearedev.team/adminapi",
    imageUrl: "https://buddyexapi.wearedev.team",
    socketUrl: "https://buddyexapi.wearedev.team",
    defaultLogo: "https://buddyexapi.wearedev.team/tokens/default.png",
    reactLoader: "#fbd334",
    toFixed: 6,
    secretKey: "BUDDYEX*26@7#$%@",
    txLink: {
      1: "https://etherscan.io/",
      56: "https://bscscan.com/",
      137: "https://polygonscan.com/",
      43114: "https://snowtrace.io/",
      250: "https://ftmscan.com/",
      42161: "https://arbiscan.io/",
      10: "https://optimistic.etherscan.io/"
    },
    rpc: {
      1: "https://mainnet.infura.io/v3/d5b89bd22ca242798dd9feb0ab031ccf",
      56: "https://bsc-dataseed.binance.org/",
      137: "https://polygon-rpc.com/",
      43114: "https://api.avax.network/ext/bc/C/rpc",
      250: "https://rpcapi.fantom.network",
      42161: "https://endpoints.omniatech.io/v1/arbitrum/one/public",
      10: "https://mainnet.optimism.io",
      128: "https://http-mainnet-node.huobichain.com",
      8453: "https://mainnet.base.org",
    },
    chainId: 137
  };
} else {
  key = {
    baseUrl: "http://localhost:4013/adminapi",
    socketUrl: "http://localhost:4013",
    imageUrl: "http://localhost:4013",
    defaultLogo: "https://buddyexapi.wearedev.team/tokens/default.png",
    txLink: txLink,
    secretKey: "BUDDYEX*26@7#$%@",
    txLink: {
      1: "https://etherscan.io/",
      56: "https://bscscan.com/",
      137: "https://polygonscan.com/",
      43114: "https://snowtrace.io/",
      250: "https://ftmscan.com/",
      42161: "https://arbiscan.io/",
      10: "https://optimistic.etherscan.io/"
    },
    rpc: {
      1: "https://mainnet.infura.io/v3/d5b89bd22ca242798dd9feb0ab031ccf",
      56: "https://bsc-dataseed.binance.org/",
      137: "https://polygon-rpc.com/",

      43114: "https://api.avax.network/ext/bc/C/rpc",
      250: "https://fantom.drpc.org",
      42161: "https://endpoints.omniatech.io/v1/arbitrum/one/public",
      10: "https://mainnet.optimism.io",
      128: "https://http-mainnet-node.huobichain.com",
      8453: "https://mainnet.base.org",
    },
    defaultLogo: "https://buddyexapi.wearedev.team/tokens/default.png",
    chainId: 137
  };
}
export default key;