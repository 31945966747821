import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import DataTable, { createTheme } from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import {
  InputLabel,
  FormControl,
  InputAdornment,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { getPairLists } from "../Action/ApiAction.js";
import { formatNumber } from "../helper/custommath.js";
import moment from "moment";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
// Recently Swap Trans Table
const columns = [
  {
    name: "Date",
    selector: (row) => row.date,
    width:"180px"
  },
  {
    name: "Pair",
    selector: (row) => row.token0symbol + ' - ' + row.token1symbol,
    width: "150px"
  },
  {
    name: "From",
    selector: (row) => row.token0,
  },
  // {
  //   name: "Token Symbol 2",
  //   selector: (row) => row.token1symbol,
  // },
  {
    name: "To",
    selector: (row) => row.token1,
  },
  {
    name: "Price",
    selector: (row) => row.price ? row.price : 0,
    width: "100px"
  },
  {
    name: "Pool Address",
    selector: (row) => {
      return row.exchangePair ? row.exchangePair.join(', ') : ''
    }
  },
  {
    name: "Exchange Names",
    selector: (row) => {
      return row.exchange ? row.exchange.join(', ') : '';
    }
  },
];

export default function PairManagement(props) {
  // For Datatable Starts
  createTheme(
    "dark",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(0,0,0,.12)",
      },
      background: {
        default: "#0d0f36",
      },
      context: {
        background: "#0d0f36",
        text: "#DA1818",
      },
      divider: {
        default: "rgb(17 17 17)",
      },
      button: {
        default: "#FFFFFF",
        focus: "rgba(255, 255, 255, .54)",
        hover: "rgba(255, 255, 255, .12)",
        disabled: "rgba(255, 255, 255, .18)",
      },
      selected: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      highlightOnHover: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      striped: {
        default: "rgba(0, 0, 0, .87)",
        text: "#FFFFFF",
      },
    },
    "dark"
  );

  const [record, setRecord] = useState({
    'data': [],
    'count': 0
  })
  const [filter, setFilter] = useState({
    skip: 1,
    limit: 10,
    search: '',
  })
  useEffect(() => {
    getSwapList(filter)
  }, [])

  async function getSwapList(reqQuery) {
    try {
      var response = await getPairLists(reqQuery)
      console.log('response: ', response);
      let resultArr = []
      response && response.result && response.result.length > 0 && response.result.map((item) => {
        console.log('isstem: ', item);
        resultArr.push({
          'token0': item.token0,
          'token1': item.token1,
          "token0symbol": item.token0symbol,
          "token1symbol": item.token1symbol,
          'price': item && item.price ? formatNumber(item.price, 8) : '0',
          "exchange": item.exchangeNames,
          "exchangePair": item.exchangePair,
          'date': moment(item.createdAt).format('MMMM DD,YYYY hh:mm'),
        })
      })
      setRecord({
        'data': resultArr,
        count: response.totalRecords
      })
    }
    catch (err) {
    }
  }

  const handlePageChange = page => {
    let filterData = { ...filter, ...{ 'page': page, 'skip': page } }
    setFilter(filterData)
    getSwapList(filterData)
  };

  const handleSearch = async (event) => {
    let filterData = { ...filter, ...{ 'search': event.target.value } }
    setFilter(filterData)
    getSwapList(filterData)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    let filterData = { ...filter, ...{ 'page': page, 'limit': newPerPage } }
    setFilter(filterData)
    getSwapList(filterData)
  };


  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <div className="container-fluid">
                <h2 className="page_title">Pair Lists</h2>
                <div className="row py-3">
                  <div className="col-lg-12">
                    <div className="dashbox_inner">
                      <div className="top_filter_wrapper">
                        <FormControl
                          sx={{ width: "70ch" }}
                          variant="outlined"
                          size="small"
                          className="search_input"
                        >
                          <InputLabel htmlFor="outlined-adornment-search">
                            Search by From & To addresses
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-search"
                            type="text"
                            endAdornment={
                              <InputAdornment position="start">
                                <IconButton aria-label="Search Icon" edge="end">
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Search by From & To addresses"
                            onChange={handleSearch}
                          />
                        </FormControl>

                      </div>
                      <DataTable
                        columns={columns}
                        data={record.data}
                        paginationTotalRows={record.count}
                        responsive
                        theme="dark"
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        paginationServer
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}