import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import {
  Button
} from "@mui/material";
import { validation } from "./validation";
import { login } from "../Action/ApiAction";
import isEmpty from "is-empty";
import { toastAlert } from "../helper/toastAlert";
import { tokencheck } from "../helper/jwttoken"
import { setAuthToken } from '../helper/localstorage';
import { getUrlList } from "../Action/UserAction"
import config from "../config/config"
import clsx from "classnames";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  email: '',
  password: '',
  twoFACode: '',
  'showPassword': false,
}
export default function Login() {
  const history = useHistory()
  const [formValue, setFormValue] = useState(initialFormValue);
  const { email, password, twoFACode, showPassword } = formValue;
  const [load, setload] = useState(false)
  const [validateError, setValidateError] = useState({});
  const [showTwoFA, setShowTowFA] = useState(false);
  const [logodata, setLogodata] = useState();
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } }
    setFormValue(formData)
    setValidateError({})
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setload(true)
    let value = {
      email: email,
      password: password,
      twoFACode: twoFACode,
      showTwoFA: showTwoFA
    }



    let error = validation(value)
    if (!isEmpty(error)) {
      setload(false)
      setValidateError(error)
    }
    else {
      let { message, errors, token, success, status } = await login(value);
      if (!isEmpty(errors)) {
        setload(false)
        setValidateError(errors)
      }
      else if (success == true) {
        if (status == 'TWO_FA') {
          setload(false)
          setShowTowFA(true)
        }
        else {
          setAuthToken(token)
          var check = await tokencheck(token)
          if (check && check.role != '') {
            localStorage.setItem("adminrole", check.role)
            localStorage.setItem("acesdsjofihw", check.access)
          }
          setload(false)
          toastAlert('success', message, 'login')
          // history.push('/dashboard')
          window.location.href = '/'
        }

      }
      else if (success == false) {
        setload(false)
        toastAlert('error', message)
      }
    }
  }
  const validPositive = (e) => {

    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };
  async function getList() {

    var response = await getUrlList()
    var list1 = response && response.result ? response.result : {}
    setLogodata(list1)
    console.log(list1, 'logodata')
  }
  useEffect(() => {
    getList()

  }, [])
  return (
    <>
      <ScrollToTopOnMount />
      <div className="auth_wrapper">
        <div className="container">
          <div className="auth_main">
            <img src={logo} alt="Logo" className="img-fluid mb-3" />
            <div className="auth_left">             
              <form className="auth_form">
                <h2>Welcome</h2>
                <p>PLEASE LOGIN TO ADMIN DASHBOARD</p>
                <div className="form-group">
                  <label>Email Address</label>

                  <input className="form-control" type="email" name="email"
                    onChange={handleChange} value={email} />
                  {
                    validateError.email && <p className="error-message" style={{ color: "red" }}>{validateError.email}</p>
                  }
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div class="password-container">
                    <input className="form-control" type={showPassword ? "text" : "password"} name="password" onChange={handleChange} value={password} />
                    <a style={{ color: "#0098df" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setFormValue((el) => {
                          return { ...el, ...{ showPassword: !el.showPassword } };
                        });
                      }}
                      class="toggle-password"
                    >
                      <i
                        className={clsx(
                          "fa",
                          { "fa-eye": showPassword },
                          { "fa-eye-slash": !showPassword }
                        )}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                  {
                    validateError.password && <p className="error-message" style={{ color: "red" }}>{validateError.password}</p>
                  }
                </div>
                {showTwoFA == true &&
                  <div className="form-group">
                    <label>Enter Two FA code</label>
                    <input className="form-control" type="twoFACode" name="twoFACode" onInput={validPositive}
                      onChange={handleChange} value={twoFACode} />

                    {
                      validateError.twoFACode && <p className="error-message" style={{ color: "red" }}>{validateError.twoFACode}</p>
                    }
                  </div>}
                <Link to="/forgot-password">Forgot Password?</Link>
                <div className="text-center mt-3">
                  <Button variant="contained" onClick={handleFormSubmit}>Sign In {load && <i class="fas fa-spinner fa-spin"></i>}</Button>
                </div>
              </form>
            </div>            
          </div>
        </div>
      </div>
    </>
  );
}