import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import $ from "jquery";
import {metaDetails, getAdmin } from "../Action/UserAction"
import io from 'socket.io-client';
import config from "../config/config"
import { getAuthToken } from "../helper/localstorage";
import { tokencheck } from "../helper/jwttoken";
import isEmpty from "is-empty";


let socket;
const url = config.socketUrl
socket = io(url)

export default function Navbar(props) {
  let { setOnlineUsers, setArrivalMessage, setsocket } = props;
  // let { socket, setOnlineUsers,setArrivalMessage } = props;
  const history = useHistory()
  const [data, setdata] = useState({});
  const [resctriction, setResctriction] = useState([]);
 
  useEffect(() => {
    loadScript();
  }, []);

  // useEffect(() => {
  //   // socketfunction()
  // }, [socket]);

  window.addEventListener('unload', () => {
    console.log('disconnectMe');
    socket.emit("removeUser", socket.id);
  });

  const Logout = () => {
    localStorage.clear()
    socket.emit("removeUser", socket.id);
    history.push('/login')
  }


  function loadScript() {
    // Navbar Sticky
    var t = $(".app_header");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      30 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      30 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      30 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });

    // Sidebar Add Class
    $(document).ready(function () {
      $('.topnavbar_right i').click(function () {
        $('.app_sidebar').addClass('show');
        $('body').addClass('menu_overlay');
      });
    });
  }

  async function getprofile() {
    try {
      var admintoken = localStorage.getItem('admin_token')
      var decoded = await tokencheck(admintoken)
      let data = { _id: decoded._id }
      console.log(data, 'data', decoded)
      setResctriction(decoded.restriction)
      var response = await getAdmin(data)
      console.log(response, 'responseresponse')
      var obj = {
        'username': response && response.result && response.result.username ? response.result.username : '',
        'logoURI': response && response.result && response.result.logoURI ? response.result.logoURI : '',
        'role': response && response.result && response.result.role ? response.result.role : ''
      }
      setdata(obj)
    }
    catch (err) {


    }
  }
  useEffect(() => {
    getprofile()
  }, []);
  async function getmeta() {
    try {
      var response = await metaDetails()
    var list1 = response && response.result ? response.result : {}
   console.log(list1,'l111111111111111',config.imageUrl+'/logo/'+list1.logoURI)
  
     document.title=list1 && list1.admin?list1.admin:'Welcome'

     const link = document.querySelector("link[rel~='icon']");
     if (link !== null) {
       (list1 && list1.logoURI) ?(link.href = config.imageUrl+'/logo/'+list1.logoURI):(link.href="");
     }
   
    }
    catch (err) {


    }
  }
  useEffect(() => {
    // getmeta()
  }, []);
  // Get Page header from URL
  const location = useLocation();
  const path = location.pathname;
  const pageName = path.split('/').map(segment => segment.replace(/-/g, ' ')).join(' ');

  return (
    <>
      <div className="app_header">
        <div className="container-fluid">
          <header className="py-3 navbar_main">
            <div className="d-flex flex-wrap align-items-center justify-content-end">
              <div className="topnavbar_right">
                <div className="dropdown text-end usericon_dropdown">
                  <a
                    href="#"
                    className="d-block link-body-emphasis text-decoration-none dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={data && data.logoURI ? config.imageUrl +
                        '/admin/' +
                        data.logoURI : require("../assets/images/user_img.jpg")}
                      alt="User"
                      width="52"
                      height="52"
                      className="rounded-circle"
                    />
                    <span>{data && data.username ? data.username : ''}</span>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-dark">
                    {data && resctriction && data.role != 'superadmin' && resctriction.includes('Site Settings') ?
                      <li>
                        <Link className="dropdown-item" to="/change-password">
                          Settings
                        </Link>
                      </li> : ""}
                    {data && data.role == 'superadmin' ? <li>
                      <Link className="dropdown-item" to="/change-password">
                        Settings
                      </Link>
                    </li> : ""}
                 
                    {/* <li>
                      <Link to='/profile' className="dropdown-item" >
                        Profile
                      </Link>
                    </li> */}
                    <hr className="m-0" />
                    <li>
                      <Link className="dropdown-item" to="#" onClick={Logout}>
                        Sign out
                      </Link>
                    </li>
                  </ul>
                </div>
                <i className="fa-solid fa-bars"></i>
              </div>
            </div>
          </header>

        </div>
      </div>
    </>
  );
}
